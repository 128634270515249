@use 'src/styles/mixin' as mixin;

.system {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
}

.container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 24px;
  & > :nth-of-type(2n+1) {
    background: var(--color-light-gray);
  }
}

.wrapper {
  padding: 0;
}

.buttonsContainer {
  margin-top: 24px;
  width: 200px;
  @media (max-width: 639px) {
    width: 100%;
  }
}

.name {
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  margin-left: 8px;
}

.iconBox {
  background: var(--color-white);
  border: 1px var(--color-primary-light) solid;
  width: 60px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 29px;
  }
}
