@use 'src/styles/mixin' as mixin;

.heading {
  font-family: var(--font-family);
  color: var(--color-primary-text);
  font-weight: 500;

  &.h1 {
    font-size: 32px;
    line-height: 1.3;
    font-weight: 800;

    // @include mixin.tablet {
    //   font-size: 34px;
    // }
  }

  &.h2 {
    font-size: 20px;
    line-height: 1.3;
    font-weight: 800;

    // @include mixin.tablet {
    //   font-size: 24px;
    // }
  }

  &.h3 {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 600;

    // @include mixin.tablet {
    //   font-size: 20px;
    // }
  }

  &.h4 {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 500;

    // @include mixin.tablet {
    //   font-size: 20px;
    // }
  }

  &.h5 {
    font-size: 14px;
    line-height: 1.3;
    font-weight: 400;

    // @include mixin.tablet {
    //   font-size: 18px;
    // }
  }

  &.h6 {
    font-size: 12px;
    font-weight: 300;
    line-height: 1.3;
  }

  &.p {
    font-size: 14px;
    line-height: 28px;
    font-weight: 500;
    // @include mixin.tablet {
    //   font-size: 16px;
    //   line-height: 28px;
    // }
  }
}
