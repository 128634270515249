.container {
  display: flex;
  flex-direction: column;
  max-width: 564px;
  align-self: 'center';
  gap: 24px;
}

.selectContainer {
  margin-top: 16px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: var(--color-light-gray);
  border-radius: var(--border-radius);
  position: relative;
  cursor: pointer;
}

.selectIcon {
  color: var(--color-dark-gray);
  transition: all .2s;
}

.selectIconOpen {
  transform: rotate(180deg)
}

.labelText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
}

.options {
  position: absolute;
  top: 56px;
  left: 0px;
  width: 100%;
  padding: 8px;
  border-radius: var(--border-radius);
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  z-index: 1;
}

.option {
  height: 38px;
  padding: 6px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
}

.option:hover {
  background: var(--color-primary-light);
}

.selectedOption {
  background: var(--color-primary);
  color: var(--color-contrast-text);
}

.selectedOption:hover {
  background: var(--color-primary);
  color: var(--color-contrast-text);
}

.selectLabel {
  display: flex;
  align-items: center;
  gap: 8px;
}

.currencyAvatar {
  border: 1px var(--color-primary) solid;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}