.profileContainer {
  max-width: 1056px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 256px;
  &:first-child {
    flex: 1;
  }
  @media (max-width: 1023px) {
    margin-left: 0;
  }
}

//drop block
.dropbtn {
  padding: 16px;
}

.dropdown {
  display: inline-block;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: white;
  width: 100%;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
  margin-left: -20px;
  margin-top: 10px;
  text-align: center;

  & span {
    color: black;
    padding: 7px 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & span:hover {background-color: #ddd;}
}

.dropdown:hover {
  & .dropdownContent {display: block;}
}
