@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import-normalize;
@import 'react-toastify/dist/ReactToastify.css';

:root {
  --font-family: 'Poppins', sans-serif;
  --color-primary-text: #262626;
  --color-contrast-text: #ffffff;
  --color-heading-text: #fafafa;
  --color-gray-text: #7e8299;
  --color-light-gray-text: #bdbdbd;
  --color-light-gray: #F5F5F5;
  --color-dark-gray: #79747E;
  --color-gray: #bdbdbd;
  --color-primary: #5260FE;
  --color-primary-light: #EBF3FF;
  --color-primary-dark: #5260FE;
  --color-primary-hover: rgba(123, 24, 245, 0.08);
  --color-white: #ffffff;
  --color-main-bg: #f6f6f6;
  --color-error: #dd5757;
  --color-error-bg: #ffdcdc;
  --color-disable: #a6a6a6;
  --color-disable-bg: #e0e0e0;
  --color-success: #35af60;
  --color-success-bg: #dcffdd;
  --color-warning: #ffb302;
  --color-warning-bg: #ffeab9;
  --color-primary-border: #f6f9fc;
  --color-row-bg: #fafafa;
  --color-secondary-bg: rgba(255, 255, 255, 0.94);
  --color-card-bg: #e9e9e9;
  --color-secondary: #3498db;
  --radius-primary: 8px;
  --radius-secondary: 20px;

  --height-small: 50px;
  --height-medium: 56px;
  --height-large: 70px;

  --height-input-small: 40px;
  --height-input-medium: 56px;

  --width-btn-max: 312px;
  --width-btn-min: 100px;
  --box-shadow: 0 2px 31px 2px rgba(0, 0, 0, 0.11);
  --border-radius: 8px
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  font-size: 16px;
  /* font-weight: 500; */
  box-sizing: border-box;
  background-color: var(--color-main-bg);
  min-height: 100vh;
}

*::after,
*::before {
  box-sizing: inherit;
}

ul[class],
ol[class] {
  /* padding: 0; */
  list-style: none;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  letter-spacing: -1px;
  /* color: #262626; */
}

ul[class] {
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

input,
button,
textarea,
select {
  font: inherit;
}

a {
  text-decoration: none;
}
