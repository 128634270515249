.container {
  display: flex;
  flex-direction: column;
  gap: 16px
}

.flexBox {
  display: flex;
  gap: 8px;
}

.titleText {
  font-weight: 400;
  font-size: 14px;
}

.valueText {
  font-weight: 500;
  font-size: 14px;
}