.header{
  display: flex;
  margin-top: 10px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
}

.col{
  flex: 1;
}

.button {
  text-align: left;
  margin-top: 24px;
  width: 208px;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.icon {
  cursor: pointer;
  // margin-left: 25px;
  font-size: 20px;
}

.deletePopup {
  display: inline-block;
}

.deletePopup .text {
  display: inline-block;
  text-align: center;
}

.buttonsRemoveBlock {
  display: flex;
  flex-direction: row;
  padding-top: 32px;
  gap: 15px;
  & > button {
    flex: 1;
  }
}

.noAccounts {
  font-size: 14px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  color: var(--color-light-gray-text);
}

.mobilePaperCard {
  margin-bottom: 16px;
  display: flex;
  & >div:first-child {
    flex: 1;
  }
}

.mobileItem {
  display: flex;
  gap: 8px;
  align-items: center;
  min-height: 45px;
}

.mobileActionWrapper {
  flex-shrink: 0;
  color: var(--color-dark-gray);
  margin-top: 8px;
  width: 36px;
  & svg {
    cursor: pointer;
  }
  position: relative;
}

.mobileActionsMenuShow {
  position: absolute;
  background: #ffffff;
  right: 20px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: 0px 1px 16px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 8px;
  z-index: 100;

  & svg {
    padding: 10px;
    box-sizing: content-box;
  }
}

.hidden {
  display: none;
}

// table
$breakpoint: 750px;
$heading-color: #43BAC0;

* {
  box-sizing: border-box;
}
.tableWrapper {
  width: 100%;
  max-width: 1000px;
}


/* Table column sizing
================================== */
.idCell  { width: 10% !important; gap: 16px;}
.otherCell  { width: 15%; }

.idCell svg {
  // margin: 0 15px 0 0;
}
/* Apply styles
================================== */


.Rtable {
  display: flex;
  flex-wrap: wrap;
  // margin: 0 0 4em 0;
  padding: 16px;
  box-shadow: var(--box-shadow);
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  .RtableRow:nth-of-type(2n) {
    background: var(--color-light-gray);
  }

  .RtableRow {
    width: 100%;
    display: flex;
    overflow-wrap: anywhere;
    padding: 5px;
    font-size: 14px;
    font-weight: 400;
    padding: 16px;
    gap: 8px;
    border-radius: 8px;

    .leftAlignCell {
      justify-content: left !important;
    }

    .RtableCellHiddenText{
      & span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .RtableCell {
      box-sizing: border-box;
      flex-grow: 1;
      overflow: hidden;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;

      &.columnHeading {
        background-color: $heading-color;
        color: white;
        padding: 1em;
      }

      .RtableCellHeading {
        display: none;
      }
    }
  }

  .RtableRowHead {
    font-size: 14px;
    font-weight: 500;
    box-shadow: var(--box-shadow);
    min-height: 52px;
    margin-bottom: 20px;

  }
}

/* Responsive
==================================== */
// @media all and (max-width: $breakpoint) {
//   .RtableCollapse  {
//     display: block;
//     width: 100%;
//     padding: 1em;
//     box-shadow: none;

//     .RtableRow {
//       box-sizing: border-box;
//       width: 100%;
//       display: flex;
//       flex-wrap: wrap;
//       margin-bottom: 2em;
//       box-shadow: 0 0 40px rgba(0,0,0,0.2);

//       .RtableCell {
//         width: 100% !important;
//         display: flex;
//         align-items: center;

//         .RtableCellHeading {
//           display: inline-block;
//           flex: 1;
//           max-width: 120px;
//           min-width: 120px;
//           color: $heading-color;
//           font-weight: 700;
//           border-right: 1px solid #ccc;
//           margin-right: 1em;
//         }
//       }
//     }

//     .RtableRowHead {
//       display: none;
//     }
//   }
// }

// Non-Flex modernizer fallback
.no-flexbox .Rtable {
  display: block;

  &.RtableCell {
    width: 100%;
  }
}
