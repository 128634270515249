@use 'src/styles/mixin' as mixin;

.container {
  padding: 16px;
}

.flexBox {
  display: flex;
  gap: 24px;
  margin: 24px 0;
  @media (max-width: 639px) {
    flex-direction: column-reverse;
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  @media (max-width: 639px) {
    flex-direction: column;
  }
}

.skeletonBtn {
  width: 80%;
  margin: 25px auto 0;
}

.button {
  flex: 1;
  @media (max-width: 639px) {
    width: 100%;
  }
}

.list,
.listSkeleton {
  padding-left: 0px;
  list-style: none;
  flex: 1;
  // width: 100%;
  // & > li:not(:last-child) {
  //   margin-bottom: 4px;
  // }
}

.listSkeleton {
  display: block;
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
}

.icon {
  overflow: hidden;
  width: 60px;
  height: 40px;
  background-color: inherit;
  border: 1px solid var(--color-primary-border);
  border-radius: var(--radius-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-contrast-text);

  & > img {
    max-width: 100%;
  }
}

.copyIcon {
  position: absolute;
  right: 16px;
  line-height: 0;
  width: 26px;
  height: 26px;
}

.checkedIcon {
  position: absolute;
  transition: opacity 0.4s;
  left: 0;
  top: 0;
  &.hide {
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }
}
