.modal {
  position: absolute;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--color-main-bg);
  overflow: auto;
  width: 100%;
  max-width: 400px;
  min-width: 200px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  gap: 24px;

  .content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  .button {
    width: 180px;
  }
}

.container {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  transition: 0.5s;

  &.close {
    opacity: 0;
    pointer-events: none;
  }

  &.open {
    opacity: 1;
    pointer-events: all;
  }
}
