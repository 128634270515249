@use 'src/styles/mixin' as mixin;

$padding: 14px;
$placeholder-padding: 5px;
$legend-font-size: 10px;

.customSelect {
  text-align: start;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--color-primary-text);
  border-radius: 6px;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  overflow: visible;

  .wrapperTextField {
    width: 100%;
  }

  .textField {
    padding-right: $padding * 2;
  }

  @include mixin.tablet {
    font-size: 16px;
    line-height: 22px;
  }

  &.medium {
    // min-height: var(--height-input-small);
    // height: var(--height-input-small);

    @include mixin.tablet {
      min-height: var(--height-input-medium);
    }
  }

  &.small {
    min-height: var(--height-input-small);
    height: var(--height-input-small);
  }
}

.caret {
  position: absolute;
  display: flex;
  right: $padding;
  top: 40%;
  z-index: 10;
  width: 14px;
  height: 14px;
  cursor: pointer;
  align-items: center;

  &::before {
    content: '';
    translate: 20% 30%;
    border: 5px solid transparent;
    border-top-color: var(--color-dark-gray);
  }

  &.open {
    rotate: 180deg;
  }

  &:hover {
    opacity: 0.7;
  }
}

.options {
  position: absolute;
  width: 100%;
  height: 0;
  left: 0;
  border-radius: 6px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  opacity: 0;
  list-style: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-disable);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transform: translateY(-17px);
}

.options .noFound {
  margin: auto;
}

.options.show {
  height: auto;
  z-index: 100;
  opacity: 1;
  transform: translateY(0);
}

.option {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 14px;
  min-height: var(--height-input-small);
  cursor: pointer;
  transition: all 0.6ms ease;
  min-width: 0;

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-primary-border);
  }

  &.selected {
    background-color: var(--color-primary);
    color: var(--color-contrast-text);
  }
  &.highlighted {
    background-color: var(--color-primary-light);
    outline: none;
  }
  &.highlighted.selected {
    background-color: var(--color-primary);
  }
}
