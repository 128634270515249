.footerWrapper {
  background: var(--color-secondary-bg);
  backdrop-filter: blur(7px);
  width: 100vw;
  height: 60px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;

  @media only screen and (max-width: 768px) {
    padding: 0 16px;
    justify-content: space-between;
    gap: 48px;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 0 20px;
  }
}

// .footerContent {
//   display: flex;

//   max-width: max-content;
//   text-align: right;
//   font-size: 12px;
//   font-weight: 400;
// }

.copyright {
  color: var(--color-light-gray-text);
  white-space: nowrap;
}
