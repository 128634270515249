@use 'src/styles/mixin' as mixin;

.headerWrapper {
  width: 100vw;
  background: var(--color-secondary-bg);
  backdrop-filter: blur(7px);

  .headerContent {
    display: flex;
    min-height: 75px;
    padding: 0 24px 0 46px;
    margin: 0 auto;
    align-items: center;

    @media only screen and (max-width: 768px) {
      padding: 0 16px;
    }

    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      padding: 0 20px;
    }

    .logoWrapper {
      display: flex;
      align-items: center;
      gap: 3px;
    }
  }

  .user {
    margin-left: auto;
  }
}
