@use 'src/styles/mixin' as mixin;

.label {
  font-family: var(--font-family);
  color: var(--color-primary-text);
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  text-transform: capitalize;

  &.new,
  &.success {
    color: var(--color-success);
  }

  &.pending {
    color: var(--color-warning);
  }

  &.decline {
    color: var(--color-disable);
  }

  &.failed,
  &.cancelled {
    color: var(--color-error);
  }

  &.paid {
    color: var(--color-secondary);
  }

  @include mixin.tablet {
    font-size: 16px;
    line-height: 28px;
  }
}
