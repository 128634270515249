@use 'src/styles/mixin' as mixin;

.scrollContainer {
  display: block;
  max-height: calc(100dvh - 490px);
  overflow-y: auto;
}

.tableWrapper {
  width: 100%;
  box-sizing: border-box;
}

.tableContainer {
  width: 100%;
  overflow-x: auto;
  box-sizing: border-box;
  padding: 16px;
  box-shadow: 0px -7px 25.5px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
}

.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.noAccounts {
  display: flex;
  td {
    flex: 1;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
  box-sizing: border-box;
  background: var(--color-white);
  color: var(--color-primary-text);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

thead {
  background: var(--color-white);
  box-shadow: 0px 4px 4px rgba(164, 164, 164, 0.25);
  border-radius: 10px;
}

thead tr {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 1.5fr 1.5fr; // то же распределение, что и в thead
}

.grid {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 1.5fr 1.5fr; // то же распределение, что и в thead
}

tbody:before {
  content: '.';
  line-height: 24px;
  text-indent: -99999px;
}

th,
td {
  padding: 16px 9px;
  min-width: 60px;
  text-align: center;
  line-height: 24px;
  &:first-child {
    padding-left: 16px;
  }

  &:last-child {
    padding-right: 16px;
  }
}

th {
  font-weight: 600;

  &:first-child {
    text-align: left;
  }
}

td {
  word-break: break-all;
  word-break: break-word;
}

tr:nth-child(odd) td {
  background: var(--color-main-bg);
}

.actionsWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.icon {
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    color: var(--color-secondary);
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 32px;
}

.deletePopup {
  overflow: hidden auto;
  width: calc(90vw - 40px); // 40px - popup padding
  text-align: center;

  @include mixin.phone {
    overflow: auto;
    padding: 12px;
    width: 440px;
  }

  .popupTitle {
    font-weight: 500;
    margin-bottom: 12px;
  }

  .idText {
    font-weight: bold;
    word-break: break-all;
    word-break: break-word;
    margin-top: 8px;
  }

  .buttonsRemoveBlock {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 48px;

    @include mixin.tablet {
      justify-content: flex-start;
      flex-wrap: nowrap;
    }

    & > button,
    & > a {
      flex: 1 1 100%;

      @include mixin.tablet {
        flex: 1 1 50%;
      }
    }
  }
}
