.container {
  display: flex;
  align-items: center;
  gap: 16px;
  // padding: 24px;
  // box-shadow: var(--box-shadow);
  border-radius: 8px;
  box-sizing: border-box;
}

.emptyProgress {
  border-radius: inherit;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  &.emptyInProgress {
    background-color: var(--color-primary-light);
  }
  &.emptyExpired {
    background-color: var(--color-error-bg);
  }
}
.fillProgress {
  border-radius: inherit;
  height: 100%;
  width: 100%;
  position: absolute;

  &.fillInProgress {
    background-color: var(--color-primary);
  }
  &.fillExpired {
    background-color: var(--color-error);
  }
}
.title {
  position: absolute;
  color: var(--color-contrast-text);
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.percentage {
  color: var(--color-primary);
  right: 16px;
  min-width: 49px;
  max-width: 49px;
}

.title {
  left: 16px;
}

.progressBar {
  width: 100%;
  height: 32px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
