.modal {
  position: absolute;
  padding: 24px;
  border-radius: 20px;
  background-color: var(--color-main-bg);
  overflow: auto;
  //max-width: 400px;
  min-width: 300px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin: 0 16px;
  @media (max-width: 767px) {
    padding: 16px;
  }
}

.container {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  transition: 0.5s;

  &.close {
    opacity: 0;
    pointer-events: none;
  }

  &.open {
    opacity: 1;
    pointer-events: all;
  }
}
