.buttonsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 24px;
  & button, a {
    width: 176px;
    @media (max-width: 639px) {
      width: 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 639px) {
    gap: 12px;
  }
}

.accountLink {
  margin-top: 32px;
}

.preloader {
  position: relative;
  min-height: 300px;
}