.styledLink {
  max-width: var(--width-btn-max);
  border-radius: var(--radius-primary);

  &.fullWidth {
    width: 100%;
  }

  &:focus {
    outline: 2px solid var(--color-primary-dark);
  }
  &:active {
    transform: scale(0.96);
    outline: none;
  }
}
