.paperWrapper {
  // border: var(--color-primary-border) solid 1px;
  // box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
  //   0 30px 60px -30px rgb(0 0 0 / 30%);
  width: 100%;
  max-width: 1056;
  background-color: var(--color-white);
  position: relative;
  padding: 24px;
  box-shadow: var(--box-shadow);
  @media (max-width: 640px) {
    padding: 16px;
  }
  // @media only screen and (max-width: 1024px) {
  //   padding: 20px;
  // }
}
