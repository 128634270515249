@use 'src/styles/mixin' as mixin;

.rootContainer {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}

.mainContainer {
  display: flex;
  box-sizing: border-box;
  position: relative;
  min-height: calc(100dvh - 135px);
    @media (max-width: 1023px) {
      flex-direction: column; // Применяется только для экранов меньше 767px
    }
}

.content {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1;

  @include mixin.tablet {
    padding: 20px;
  }

  @include mixin.laptop {
    // max-width: 1100px;
    padding: 24px;
  }

  @include mixin.desktop {
    // max-width: 1180px;
  }

  // @include mixin.bigScreen {
  //   max-width: 1440px;
  // }
}