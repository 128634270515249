.dropdown {
  position: relative;
  font-family: Arial, sans-serif;
  font-size: 16px;
  max-width: fit-content;

  .input {
    background-color: #fff;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: all 0.3s;
    transform: rotate(0deg);

    .rotateForOpenedList {
      transform: rotate(180deg);
      transition: transform 0.3s;
    }

    .rotateForClosedList {
      transform: rotate(0deg);
      transition: transform 0.3s;
    }
  }

  .menu {
    position: absolute;
    top: 30px;
    left: -107px;
    right: 0;
    max-height: 289px;
    width: 320px;
    overflow: scroll;
    margin-top: 4px;
    padding: 8px;
    list-style: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    z-index: 10;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .item {
      margin: 6px;
      padding: 8px 16px;
      cursor: pointer;
      border-radius: 20px;

      &:hover {
        background-color: var(--color-primary-light);
      }
    }

    .selected {
      background-color: var(--color-primary-dark);
      color: #ffffff;
      &:hover {
        background-color: var(--color-primary-dark);
      }
    }
  }
}
