@use 'src/styles/mixin' as mixin;

.container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 100%;

  @media (max-width: 425px) {
    width: 100%;
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;
  max-width: 220px;
  @media (max-width: 425px) {
    max-width: 100%;
    & button {
      max-width: 100%;
    }
  }
}

.skeletonBtn {
  width: 80%;
  margin: 25px auto 0;
}

.preloader {
  position: relative;
  min-height: 550px;
}

.label {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 8px;
}

.value {
  font-weight: 500;
  font-size: 16px;
}

.profileItem {
  max-width: 100%;
  text-align: start;
  overflow: hidden;
  min-width: 250px;
}

.profileInfoWrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  margin-bottom: 32px;
  justify-items: start;
  @media (min-width: 767px) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));

  }
}
