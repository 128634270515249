@use 'src/styles/mixin' as mixin;

.button {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  // max-width: var(--width-btn-max);
  min-width: var(--width-btn-min);
  cursor: pointer;
  text-align: center;
  transition: all 150ms ease;
  padding: 16px 27px;
  text-transform: capitalize;
  text-decoration: none;
  outline: 0;
  border-radius: var(--radius-primary);
  border: 0;
  @media (max-width: 767px) {
    max-width: 100%;
    // padding: 16px 12px;
  }

  &.fullWidth {
    width: 100%;
  }

  &.medium {
    min-height: var(--height-medium);

    @include mixin.tablet {
      min-height: var(--height-medium);
    }
  }

  &.small {
    min-height: var(--height-small);
  }

  &.filled {
    background-color: var(--color-primary);
    color: var(--color-contrast-text);
  }

  &.outlined {
    border: 1px solid var(--color-primary);
    background-color: transparent;
    color: var(--color-primary);
  }
  &.text {
    background-color: transparent;
    color: var(--color-primary);
  }

  &.outlined,
  &.text {
    &:hover::after {
      content: '';
      background-color: var(--color-primary-light);
      opacity: 0.2;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      border-radius: var(--radius-primary);
    }
  }

  &:focus-visible {
    outline: 2px solid var(--color-primary-dark);
  }

  &:active {
    filter: brightness(0.9);
    transform: scale(0.96);
    outline: none;
  }

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    // color: var(--color-disable);
    // background-color: var(--color-disable-bg);
    opacity: 60%;
    cursor: default;
    pointer-events: none;
  }

  &.disabled {
    color: var(--color-disable);
    background-color: var(--color-disable-bg);
    cursor: default;
    pointer-events: none;
  }
}
