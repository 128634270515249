.container {
  display: grid;

  .avatar {
    grid-row: 1 / span 2;
    place-self: center;
    margin-right: 14px;
  }
  .name {
    display: flex;
    gap: 0.1em;
    grid-column: 2/3;
    margin-left: 2px;
  }
  .email {
    margin-left: 2px;
  }

}
