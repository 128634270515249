@use 'src/styles/mixin' as mixin;

$padding: 14px;
$placeholder-padding: 5px;
$legend-font-size: 10px;

.wrapper{
  background: linear-gradient(90deg, #4459af 3%, #4459af 19.47%, #a060d7 99.22%);
  padding: 20px;
  @media (max-width: 639px) {
    padding: 16px;
  }
}

.container {
  text-align: start;
  position: relative;
  box-sizing: border-box;
  width: 348px;
  height: 56px;
  display: flex;
  align-items: center;
  color: var(--color-primary-text);
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 6px $padding;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  overflow: visible;

  @media (max-width: 639px) {
    width: 100%;
  }
}

.infoItem {
  display: flex;
  align-items: center;
  padding: 10px 0;
  flex: 1;
  & span {
    font-family: var(--font-family);
    padding-left: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-primary);
  }
}

.infoSelected {
  padding: 20px 0;
  color: var(--color-primary);
}

.options {
  position: absolute;
  width: 348px;
  height: 0;
  top: 60px;
  left: 0;
  border-radius: 8px;
  overflow-y: auto;
  margin: 0;
  padding: 8px;
  opacity: 0;
  list-style: none;
  background-color: var(--color-white);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  // transform: translateY(-16px);
  @media (max-width: 639px) {
    width: 100%;
  }
}

.options.show {
  height: auto;
  z-index: 100;
  opacity: 1;
  transform: translateY(0);
}

.option {
  box-sizing: border-box;
  color: var(--color-primary);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 6px 14px;
  min-height: var(--height-input-small);
  cursor: pointer;
  transition: all 0.6ms ease;
  min-width: 0;
  transition: all .2s;

  &:hover {
    background: var(--color-primary-light);
    transition: all .2s;
  }

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--color-primary);
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--color-primary-border);
  // }

  &.selected {
    background-color: var(--color-primary);
    color: var(--color-contrast-text);
    & span {
      color: var(--color-contrast-text);
    }
    & svg {
      color: (var(--color-contrast-text))
    }
  }
}

.arrowWrapper {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.dropList {
  padding: 0;
  overflow: hidden;
  & li {
    padding-left: 24px;
  }
  & span {
    font-size: 14px;
  }
}

.dropButton {
  color: var(--color-primary);
  &:hover {
    background: transparent;
  }
}