$secondary-color: #eee;
$tab-height: 40px;
$transition-duration: 0.3s;

.tabsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tabsHeader {
  display: flex;
  border-bottom: 2px solid $secondary-color;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  flex: 1;

  font-size: 16px;
  border: none;
  background-color: transparent;
  color: var(--color-gray);
  outline: none;
  border-bottom: 2px solid transparent;
  transition: all $transition-duration ease;

  &.active {
    border-bottom: 2px solid var(--color-primary);
    color: var(--color-primary);
  }
}

.tabContent {
  padding: 20px;
  font-size: 16px;
  color: #333;
}