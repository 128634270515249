.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  overflow-y: auto;
  padding: 30px 20px;
}

.modal {
  position: relative;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--color-main-bg);
  width: fit-content;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.closeIcon {
  align-self: flex-end;
  cursor: pointer;
}

.title {
  align-self: center;
}

.content {
  margin-top: 24px;
}

.button {
  width: 180px;
}