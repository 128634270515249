@use 'src/styles/mixin' as mixin;

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form {
  display: flex;
  width: 460px;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  @include mixin.tablet {
    margin: 0;
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  gap: 24px;
}

.skeletonBtn {
  width: 80%;
  margin: 25px auto 0;
}

.preloader {
  position: relative;
  min-height: 550px;
}

.flexContainer {
  display: flex;
  gap: 24px;
  div {
    flex: 50%
  }
}

.subTitle {
  margin-bottom: 8px;
  margin-top: 8px;
}
