@use 'src/styles/mixin' as mixin;

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.backSection {
  margin-bottom: 24px;

  @media (max-width: 639px) {
    margin-bottom: 16px;
  }
}
