@use 'src/styles/mixin' as mixin;

.paperHeader {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  margin-bottom: 24px;

  & > h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3;

    @media (max-width: 640px) {
      font-size: 18px;
    }
    // @include mixin.laptop {
    //   font-size: 16px;
    //   line-height: 22px;
    // }
  }
}
