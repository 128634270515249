@use '../../styles/mixin' as mixin;

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 410px;
  margin: 0 auto;
  @media (max-width: 767px) {
    gap: 16px;
  }
  @media (max-width: 480px) {
    width: 310px;
  }

  @include mixin.tablet {
    margin: 0;
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 24px;
  @media (max-width: 767px) {
    gap: 16px;
  }

  & > button  {
    flex: 1;
  }
}

.skeletonBtn {
  margin: 25px auto 0;
}

.preloader {
  position: relative;
  min-height: 550px;
}

.country {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #BDBDBD;
}

.system {
  color: #BDBDBD;
}