.container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  margin: 32px 0 24px;
}

.uploadFile {
  height: 140px;
  width: 166px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  border: 1px dashed;
  border-color: var(--color-primary);
  & p {
    color: var(--color-primary);
  }
  @media (max-width: 639px) {
    height: 120px;
    width: 142px;
  }
}

.fileContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 167px;
  @media (max-width: 639px) {
    max-width: 142px;
  }
}

.fileName {
  font-size: 14px;
  max-width: 147px;
  @media (max-width: 639px) {
    max-width: 132px;
  }
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.deleteIcon {
  position: absolute;
  top: -12px;
  right: -12px;
  cursor: pointer;
}