.container {
  display: inline-block;
  max-width: 440px;
}

.container .text {
  display: inline-block;
  text-align: center;
  line-height: 1.3;
}

.buttonsRemoveBlock {
  display: flex;
  flex-direction: row;
  padding-top: 32px;
  gap: 24px;
  & > button {
    flex: 1;
  }
  @media (max-width: 639px) {
    // flex-direction: column;
    // gap: 12px;
  }
}