@use 'src/styles/mixin' as mixin;

.headerContainer {
  display: flex;
  justify-content: flex-start;
  div {
    width: fit-content;
  }
  .secondaryHeader {
    margin-left: 8px;
    margin-top: 6px;
    color: var(--color-primary);
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;
  max-width: 230px;
  @media (max-width: 425px) {
    max-width: 100%;
    & button {
      max-width: 100%;
    }
  }
}
