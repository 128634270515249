@use 'src/styles/mixin' as mixin;

$padding: 14px;
$placeholder-padding: 5px;
$legend-font-size: 10px;

.customSelect {
  text-align: start;
  position: relative;
  box-sizing: border-box;
  padding-top: 18px;

  width: 100%;
  display: flex;
  align-items: center;
  color: var(--color-primary-text);
  border-radius: 6px;
  padding: 0 $padding;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  overflow: visible;

  &.medium {
    min-height: var(--height-input-small);
    height: var(--height-input-small);

    @include mixin.tablet {
      min-height: var(--height-input-medium);
    }
  }

  &.small {
    min-height: var(--height-input-small);
    height: var(--height-input-small);
  }

  &:hover {
    .fieldset {
      border-color: var(--color-primary-text);
    }
  }

  &:focus {
    .fieldset {
      border-color: var(--color-primary);
    }
    .labelPlaceholder {
      color: var(--color-primary);
    }
  }

  &.error,
  &.error:focus {
    .fieldset {
      border-color: var(--color-error);
    }
    .labelPlaceholder {
      color: var(--color-error);
    }
  }
}

.caret {
  display: flex;
  margin-left: auto;
  width: 14px;
  height: 14px;
  cursor: pointer;
  align-items: center;
  margin-top: 2px;

  &::before {
    content: '';
    translate: 20% 30%;
    border: 5px solid transparent;
    border-top-color: var(--color-dark-gray);
  }

  &.open {
    rotate: 180deg;
  }

  &:hover {
    opacity: 0.7;
  }
}

.options {
  position: absolute;
  width: 100%;
  height: 0;
  left: 0;
  border-radius: 6px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  opacity: 0;
  list-style: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-disable);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transform: translateY(-16px);
}

.options.show {
  height: auto;
  z-index: 100;
  opacity: 1;
  transform: translateY(0);
}

.option {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 14px;
  min-height: var(--height-input-small);
  cursor: pointer;
  transition: all 0.6ms ease;
  min-width: 0;

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-primary-border);
  }

  &.selected {
    background-color: var(--color-primary);
    color: var(--color-contrast-text);
  }
  &.highlighted {
    background-color: var(--color-primary-light);

  }
  &.highlighted.selected {
    background-color: var(--color-primary);
  }
}

.value {
  max-width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.labelPlaceholder {
  position: absolute;
  top: 53%;
  left: $padding;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  transform: translate(0, -50%);
  color: var(--color-light-gray-text);
  pointer-events: none;
  cursor: text;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in,
    padding-left 200ms, color 0.5s;
  z-index: 0;
  font-size: 14px;
  line-height: 20px;
}

.fieldset {
  position: absolute;
  border-radius: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-width: 0%;
  pointer-events: none;
  overflow: hidden;
  border: 1px solid var(--color-disable);
  outline: none;
  transition: border-color 0.5s;
  background-color: inherit;
  z-index: 0;
  padding-top: 8px;

  legend {
    display: block;
    height: 11px;
    max-width: 0.01px;
    visibility: hidden;
    overflow: hidden;
    width: auto;
    white-space: normal;
    font-size: $legend-font-size;
    padding: 0;
    transition: max-width 0.2s linear;
  }

  .labelFieldset {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    opacity: 0;
    visibility: visible;
    font-size: $legend-font-size;
  }
}

.dirty {
  &:hover {
    .fieldset {
      border-color: var(--color-primary-text);
    }
    .labelPlaceholder {
      color: var(--color-primary-text);
    }
  }
}

.dirty:focus,
.active:focus {
  .fieldset {
    border-color: var(--color-primary);
  }
  .labelPlaceholder {
    color: var(--color-primary);
  }
}

.dirty,
.active {
  .fieldset {
    legend {
      max-width: 90%;
    }
  }
  .fieldset.withError {
    border-color: var(--color-error);
  }
  .labelPlaceholder {
    max-width: 75%;
    top: $placeholder-padding;
    font-size: $legend-font-size;
    padding-left: $placeholder-padding;
  }

  .labelPlaceholder.labelError {
    color: var(--color-error);
  }
}

.error {
  display: block;
  position: absolute;
  bottom: -15px;
  left: 5px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: var(--color-error);

  @include mixin.tablet {
    font-size: 14px;
    line-height: 18px;
  }
}

// .input {
//   padding-top: 18px;
// }