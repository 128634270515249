.content {
  text-align: center;
  max-width: 516px;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.doneButton {
  width: 180px;
  margin-top: 24px;
}

.infoContainer {
  align-self: flex-start;
  margin-top: 16px;
}