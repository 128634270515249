.avatarContainer {
  position: relative;

  .textAvatar {
    display: flex;
    width: 23px;
    height: 23px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--color-contrast-text);
    background-color: var(--color-gray);
    font-weight: 300;
    line-height: 1.43;
    letter-spacing: -1;
  }

  .avatar {
    border-radius: 50%;

    &.hide {
      position: absolute;
      opacity: 0;
    }
  }
}
