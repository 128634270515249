
.sidebar {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 256px;
  min-width: 256px;
  height: 100dvh;
  background: linear-gradient(90deg, #4459af 3%, #4459af 19.47%, #a060d7 99.22%);
}

.logoWrapper {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.menu {
  max-width: 256px;
  align-self: flex-start;
  padding: 8px;
  margin-top: 16px;
}

.link {
  height: 56px;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  transition: all .2s;
  color: var(--color-contrast-text);
  &:hover {
    background: #B388FF33;
  }
  &.active {
    background: #B388FF99;

    & icon {
      fill: var(--color-primary);
    }
  }

}

.infoItem {
  display: flex;
  align-items: center;
  & span {
    font-family: var(--font-family);
    padding-left: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3;
    color: var(--color-contrast-text);
  }
}

.dropInfoitem {
  margin-left: 12px;
  & span {
    font-size: 14px;
  }
}

.icon {
  vertical-align: middle;
}


.dropbtn {
  padding: 16px;
  flex: 1;
  cursor: pointer;
}

.dropdown {
  height: 56px;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  transition: all .2s;
  color: var(--color-contrast-text);
}

.dropdownContent {
  width: 100%;
  z-index: 1;
  text-align: center;
  overflow: hidden;

  & span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
