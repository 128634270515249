@use 'src/styles/mixin' as mixin;

.wrapper {
  position: relative;
  width: max-content;

  label {
    height: 24px;
    display: flex;
    align-items: center;
  }
}

.box {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 8px;

  & > input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    outline: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
  }
}

.mark {
  box-sizing: border-box;
  display: block;
  border: 1px var(--color-gray) solid;
  height: 20px;
  width: 20px;
  transition: all 0.2s;
  border-radius: 2px;
  // margin-left: -16px;
}

.label {
  font-family: var(--font-family);
  color: var(--color-primary-text);
  font-weight: 400;
  font-size: 14px;

  &.labelDisabled {
    color: var(--color-disable);
  }
}

input[type='checkbox']:focus-visible ~ .mark {
  outline: 2px solid var(--color-primary-light);
}
input[type='checkbox']:checked + .mark {
  background-image: url("data:image/svg+xml,%3Csvg fill='none' stroke='%23FFF' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M5 13l4 4L19 7'%3E%3C/path%3E%3C/svg%3E");
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

input[type='checkbox']:checked:disabled + .mark {
  background-image: url("data:image/svg+xml,%3Csvg fill='none' stroke='%23a6a6a6' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M5 13l4 4L19 7'%3E%3C/path%3E%3C/svg%3E");
  border-color: var(--color-disable);
  color: var(--color-disable);
  background-color: var(--color-main-bg);
}

input[type='checkbox']:disabled + .mark {
  border-color: var(--color-disable);
  color: var(--color-disable);
  background-color: var(--color-main-bg);
}
