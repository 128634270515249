:global {
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__header {
    background-color: #ffffff;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: #ffffff !important;
  }
  .react-datepicker {
    min-height: 344px;
  }
}

.customPopper {
  z-index: 100;
  box-shadow: var(--box-shadow);
  margin-top: -10px;
}

.monthName {
  font-size: 16px;
  margin-right: 8px;
  width: 71px;
}

.calendarWrapper {
  & > div {
    width: 100%;
  }
}

.customTextField {
  width: 100%;
}

.customHeader {
  box-sizing: border-box;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 28px;
  width: 320px;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
}

.navButton {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--color-light-gray);
  }

  &:active {
    background-color: var(--color-gray);
  }
}

.day {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  font-size: 12px;
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: var(--color-primary-light) !important;
  }
}

.selectedDay {
  background-color: var(--color-primary-dark) !important;

  &:hover {
    background-color: var(--color-primary-dark) !important;
  }
}

.today {
  font-weight: 600;
  background: none;
}

.dayOfTheWeek {
  display: inline-flex;
  justify-content: center;
  width: 36px;
}
