.pdy10 {
  padding: 10px 0 10px 0;
}

.statusIcon {
  margin: 0 10px -6px 10px;
}

.clrSuccess { color: #35AF60}
.clrFailed  { color: #E22217}
.clrPending { color: #FFB302}
.clrDflt    { color: #262626}
