$breakpoint-xs: 0px;
$breakpoint-sm: 640px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1440px;

@mixin phone {
  @media only screen and (min-width: $breakpoint-xs) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin bigScreen {
  @media only screen and (min-width: $breakpoint-xl) {
    @content;
  }
}
