@use 'src/styles/mixin' as mixin;

.skeleton {
  border-radius: var(--radius-primary);
}

.medium {
  min-height: var(--height-small);

  @include mixin.tablet {
    min-height: var(--height-medium);
  }
}

.small {
  min-height: var(--height-small);
}
