@use 'src/styles/mixin' as mixin;

.wrapper {
  width: 564px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.buttonsContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
  & button {
    flex: 1;
  }
  & a {
    width: 180px;
  }

  // &::before {
  //   content: '';
  //   width: 100%;
  //   height: 2px;
  //   border-radius: 8px;
  //   background-color: var(--color-primary-border);
  //   margin-bottom: 4px;
  // }

  @include mixin.tablet {
    justify-content: flex-start;
  }
}

.skeletonBtn {
  max-width: 80%;
  margin-top: 24px;
}

.imageContainer {
  display: flex;
  margin-top: 16px;
  gap: 24px;
  overflow-x: auto;
  & div {
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  & img {
    width: 100%;
    height: 84%;
    background: var(--color-light-gray);
  }
}

.iconBox {
  background: var(--color-light-gray);
  border: 1px var(--color-primary-light) solid;
  border-radius: var(--border-radius);
  width: 60px;
  min-width: 60px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    height: 29px;
  }
}

.paymentText {
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid var(--color-gray);
}

.infoContainer {
  width: 100%;
  overflow: hidden;
  border: 1px var(--color-gray) solid;
  padding: 24px;
  border-radius: (var(--border-radius));
}
