.backLink {
  display: inline-flex;
  align-items: center;
  gap: 8px;

  .text {
    font-size: 14px;
    font-weight: 400;
  }

  &:hover {
    opacity: 0.7;
  }
}

.rotateArrow {
  transform: rotate(180deg);
}

button.backLink {
  text-decoration: none;
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  // &:focus {
  //   outline: 2px solid var(--color-primary-dark);
  // }

  &:hover {
    opacity: 0.7;
  }
}
