@use 'src/styles/mixin' as mixin;

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 33px 15px;
  max-width: 410px;
  margin: 0 auto;

  @include mixin.tablet {
    margin: 0;
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
}

.skeletonBtn {
  width: 80%;
  margin: 25px auto 0;
}

.preloader {
  position: relative;
  min-height: 550px;
}
