@use 'src/styles/mixin' as mixin;

$padding: 14px;
$placeholder-padding: 5px;
$legend-font-size: 10px;

.textField {
  display: inline-flex;
  box-sizing: border-box;
  position: relative;
  border-radius: 8px;
  font-family: var(--font-family);
  text-align: left;
  word-wrap: break-word;
  min-height: var(--height-input-medium);


  &.medium {
    // min-height: var(--height-input-small);
    // height: var(--height-input-small);

    @include mixin.tablet {
      min-height: var(--height-input-medium);
    }
  }

  &.small {
    min-height: var(--height-input-small);
    height: var(--height-input-small);
  }
}

.input {
  position: relative;
  border-radius: inherit;
  box-sizing: content-box;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: $padding;
  padding-top: 18px;
  color: var(--color-primary-text);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  outline: 0;
  background-color: transparent;
  z-index: 2;

  // @include mixin.tablet {
  //   font-size: 16px;
  //   line-height: 22px;
  // }
}

.disabled {
  color: var(--color-disable) !important;
}

.fieldset {
  position: absolute;
  border-radius: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-width: 0%;
  pointer-events: none;
  overflow: hidden;
  border: 1px solid var(--color-gray);
  outline: none;
  transition: border-color 0.5s;
  background-color: inherit;
  z-index: 1;

  legend {
    display: block;
    height: 11px;
    max-width: 0.01px;
    visibility: hidden;
    overflow: hidden;
    width: auto;
    white-space: normal;
    font-size: $legend-font-size;
    padding: 0;
    transition: max-width 0.2s linear;
  }

  .labelFieldset {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    opacity: 0;
    visibility: visible;
    font-size: $legend-font-size;
  }
}

.textField .input:hover ~ .fieldset {
  border-color: var(--color-primary-text);
}

.textField .input:focus ~ .fieldset {
  border-color: var(--color-primary);
  legend {
    max-width: 90%;
  }
}

.textField:hover ~ .fieldset {
  border-color: var(--color-primary-text);
}

.textField .input ~ .fieldset.withError,
.textField .input:focus ~ .fieldset.withError,
.textField
  .input:not(:placeholder-shown).input:not(:focus)
  ~ .fieldset.withError {
  border-color: var(--color-error);
}

.input:not(:placeholder-shown).input:not(:focus) ~ .fieldset {
  border-color: var(--color-disable);
  legend {
    max-width: 90%;
  }
}

.input:not(:placeholder-shown).input:not(:focus).input:not(.disabled):hover ~ .fieldset {
  border-color: var(--color-primary-text);
}

.labelPlaceholder {
  position: absolute;
  top: 54%;
  left: $padding;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  transform: translate(0, -50%);
  color: var(--color-light-gray-text);
  pointer-events: none;
  cursor: text;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in,
    padding-left 200ms, color 0.5s;
  z-index: 2;
  font-size: 14px;
  line-height: 20px;

  // @include mixin.tablet {
  //   font-size: 16px;
  //   line-height: 22px;
  // }
}

.textField .input:focus ~ .labelPlaceholder,
.textField
  .input:not(:placeholder-shown).input:not(:focus)
  ~ .labelPlaceholder {
  max-width: 75%;
  top: $placeholder-padding;
  font-size: $legend-font-size;
  padding-left: $placeholder-padding;
}

.input:not(:placeholder-shown).input:not(:focus) ~ .labelPlaceholder {
  color: var(--color-disable);
}

.textField .input:focus ~ .labelPlaceholder {
  color: var(--color-primary);
}

.input:not(:placeholder-shown).input:not(:focus).input:not(.disabled):hover ~ .labelPlaceholder {
  color: var(--color-primary-text);
}

.input:not(:placeholder-shown).input:not(:focus) ~ .labelPlaceholder.labelError,
.textField .input:focus ~ .labelPlaceholder.labelError,
.labelPlaceholder.labelError {
  color: var(--color-error);
}

.error {
  display: block;
  position: absolute;
  bottom: -16px;
  left: 5px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: var(--color-error);

  // @include mixin.tablet {
  //   font-size: 14px;
  //   line-height: 18px;
  // }
}
