@use 'src/styles/mixin' as mixin;

.label {
  display: flex;
  margin-left: 10px;
}

.container {
  margin: 20px 10px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 24px 16px;
  gap: 20px;

  &::before {
    content: '';
    width: 100%;
    height: 2px;
    border-radius: 30px;
    background-color: var(--color-primary-border);
    margin-bottom: 4px;
  }

  @include mixin.tablet {
    justify-content: flex-start;
  }
}